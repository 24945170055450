<script setup lang="ts" generic="T extends keyof GA4Events">
import { GA4Events } from "nuxt-ga4"
import { UseIntersectionObserverOptions } from "@vueuse/core"

const props = withDefaults(
  defineProps<{
    promoId: string
    promoLocation: string
    title: string
    observerOptions?: UseIntersectionObserverOptions
  }>(),
  {
    observerOptions: {
      threshold: 0.9
    }
  }
)

const { observerOptions, promoId, promoLocation, title } = toRefs(props)

const container = ref<HTMLElement>()
const componentEvent = ref<any>()

useIntersectionObserver(
  container,
  (entries, observer) => {
    for (let entry of entries) {
      if (entry.isIntersecting) {
        componentEvent.value?.sendEvent({ clearEcommerce: false })
        observer.disconnect()
      }
    }
  },
  observerOptions
)

/**
 * @description Define view_promotions payload event
 */
const GAViewPromotionPayload = computed<Omit<GA4Events[T], "event">>(() => {
  return {
    event: "custom_event",
    action: "view_promotions",
    ecommerce: {
      promotion: {
        promotion_id: promoId.value ?? "",
        promotion_name: title.value ?? "",
        location_id: promoLocation.value ?? ""
      }
    }
  }
})
</script>

<template>
  <div ref="container">
    <GAEvent
      event="custom_event"
      :eventKey="`view_promotions_${promoId}`"
      :payload="GAViewPromotionPayload"
      :lazy="true"
      ref="componentEvent"
    >
      <slot />
    </GAEvent>
  </div>
</template>
