<script setup lang="ts" generic="T extends keyof GA4Events">
import { GA4Events } from "nuxt-ga4"

const props =
  defineProps<{
    promoId: string
    promoLocation: string
    title: string
  }>()

const { promoId, promoLocation, title } = toRefs(props)

const componentEvent = ref<any>()

/**
 * @description Define select_promotion payload event
 */
const GASelectPromotionPayload = computed<Omit<GA4Events[T], "event">>(() => {
  return {
    event: "custom_event",
    action: "select_promotion",
    ecommerce: {
      promotion: {
        promotion_id: promoId.value ?? "",
        promotion_name: title.value ?? "",
        location_id: promoLocation.value ?? ""
      }
    }
  }
})
/**
 * @description Send event manualy
 */
const sendGA4EventPromotion = () => {
  componentEvent.value?.sendEvent({ clearEcommerce: false })
}
</script>

<template>
  <div @click.native.capture="sendGA4EventPromotion">
    <GAEvent
      event="custom_event"
      :eventKey="`select_promotion_${promoId}`"
      :payload="GASelectPromotionPayload"
      :lazy="true"
      ref="componentEvent"
    >
      <slot />
    </GAEvent>
  </div>
</template>
